import React from 'react';
import { Button } from 'rsuite';
import config from '../config.json';

const DownloadPdfWithQr = props => {
    let docId = localStorage.getItem('docId');		
    if(!docId) {
        return;
    }
    const url = `${config.backendHostName}/document/download/${docId}`;

    return (
        <>
            <h3>Результат</h3>
            <Button appearance="primary" size="lg" href={url}>СКАЧАТЬ ДОКУМЕНТ</Button>
            <p>Если планируется частое использование сервиса с одинаковыми настройками, сохраните профиль. Без регистрации. Это нужно для того, чтобы в следующий раз не задавать размер и расположение QR кода в документе. Просто перейдите по кнопке Далее.</p>
        </>
    );

}

export default DownloadPdfWithQr;