import React, { useState } from 'react';
import { Steps } from 'rsuite';
import { Panel } from 'rsuite';
import { ButtonGroup } from 'rsuite';
import { Button } from 'rsuite';
import { Modal } from 'rsuite';
import Switch from './utils/Switch';
import PdfViewer from './pdfViewer/PdfViewer';
import QrCodeSettings from './qrCodeSettings/QrCodeSettings';
import QrCodeContent from './qrCodeContent/QrCodeContent';
import ProfileSelect from './profileSelect/ProfileSelect';
import { updateQrCodeData } from './services/qrCodeServices';
import DownloadPdfWithQr from './downloadPdfWithQr/DownloadPdfWithQr';
import ErrorProcessingPdf from './errorProcessingPdf/ErrorProcessingPdf';
import ProfileSave from './profileSave/ProfileSave';
import './AppSteps.css';
import { useEffect } from 'react';
import { getProfiles } from './utils/RestHelper';

const AppSteps = () => {

	//maximum steps user can pass
	const maxSteps = 5;

	//pages
	const pagePdfViewer = 0;
	const pageProfileSelect = 1;
	const pageQrCodeSettings = 2;
	const pageQrCodeContent = 3;
	const pageDownloadPdfWithQr = 4;
	const pageProfileSave = 5;

	const [step, setStep] = React.useState(0);
	
	//pdf related
    const [pdfFile, setPdfFile] = useState(null);
    const [pageSize, setPageSize] = useState({width: 0, height: 0});
    const [pageNumber, setPageNumber] = useState(1);

	//qr size and position
    const [sizeQr, setSizeQr] = useState(128);
    const [qrPosition, setQrPosition] = useState({left:0,top:0});
	
	//content type
    const [contentType, setContentType] = useState("CCT01");
    const [docType, setDocType] = useState("CDT01");
    const [textQr, setTextQr] = useState("");
    const [urlQr, setUrlQr] = useState("");
    const [textPage, setTextPage] = useState("");  
    const [isQrIncludedInAllPages, setIsQrIncludedInAllPages] = useState(false);  

	//profile selection
	const [userLogin, setUserLogin] = useState(null);
	const [userProfiles, setUserProfiles]= useState([]);
    const [selectedProfile, setSelectedProfile] = useState(null);
		
    const [canDownload, setCanDownload] = useState(false);

	//modals - validation error messages
	const [modalAlertMessage, setModalAlertMessage] = React.useState(null);
	const [openModalAlert, setOpenModalAlert] = React.useState(false);
	const handleOpenModalAlert = (message) => {
		setModalAlertMessage(message);
		setOpenModalAlert(true);
	}
	const handleCloseModalAlert = () => {
		setOpenModalAlert(false);	
		setModalAlertMessage(null);
	}

	const pdfScale = 1.334;
	let currentStep = step;

	const setQrSettingsByProfile = data => {
		if (!data) {
			return;
		}
		setSizeQr(data.size);
		setQrPosition({left:data.posX,top:data.posY});
		//setPageNumber(data.pageNum);
	}

	useEffect(() => {
		const loadProfile = () => {
			let userLoginStored = localStorage.getItem('userLogin');
			if (!userLoginStored) {
				return;
			}
			setUserLogin(userLoginStored);
			setUserProfiles([]);

			getProfiles(userLoginStored)
			.then(data => {
				if (!data) {
					return;
				}
				setUserProfiles(data);
			},
			reason => {
				console.log(reason);
			});
		}

		loadProfile();

	//temp debug
	/*
		setStep(3);
		setCanDownload(true);
	*/	
	//end temp debug
	}, []);	

	//Content type step is finished, call api to update qr details
	const onQrContentTypeClose = () => {
		return updateQrCodeData({
			contentType,
			textQr,
			urlQr,
			docType,
			textPage,
			sizeQr,
			qrPosition,
			pageNumber,
			isQrIncludedInAllPages
		});
	}

	/**
	 * Validations before leaving step (1) Pdf file selection
	 * @returns boolean
	 */
	const isValidPagePdfSelection = () => {
		if(pdfFile == null) {
			handleOpenModalAlert('Выберите PDF файл');
			return false;
		}
		if(!(pageNumber > 0)) {
			handleOpenModalAlert('Выберите страницу для размещения QR кода');
			return false;
		}
		return true;
	};

	/**
	 * Validations before leaving step (4) Qr Code Content
	 * @returns boolean
	 */
	const isValidPageQrCodeContent = () => {
		switch (contentType) {
			case 'CCT01':
				if (!textQr) {
					handleOpenModalAlert('Необходимо задать текст');
					return false;
				}
				break;
			case 'CCT02':
				if (!urlQr) {
					handleOpenModalAlert('Необходимо задать ссылку');
					return false;
				}
				break;
			case 'CCT04':
				if (!textPage) {
					handleOpenModalAlert('Необходимо задать текст для страницы');
					return false;
				}
				break;
			default:
				return true;
		}
		return true;
	}

	/**
	 * Validate if can switch to the next step
	 * @param {boolean} isBack 
	 * @returns boolean
	 */
	const canLeaveCurrentStep = isBack => {
		switch (currentStep) {
			case pagePdfViewer:
				return isValidPagePdfSelection();
			case pageQrCodeContent:
				return isBack || isValidPageQrCodeContent();
			default:
				return true;
		}
	}

	/**
	 * Triggered on step change
	 * @param {*} nextStep 
	 * @returns 
	 */
	const onChange = async (nextStep, isBack = false) => {
		//validate data before step change
		if (!canLeaveCurrentStep(isBack)) {
			return;
		}

		const step = nextStep < 0 ? 0 : nextStep > maxSteps ? maxSteps : nextStep; 

		if (step === pageDownloadPdfWithQr) {
			const canDownload = await onQrContentTypeClose();
			setCanDownload(canDownload);
		} else {
			setCanDownload(false);
		}

		//change step
		setStep(step);

		//post step change operations
		switch (step) {
			case pageDownloadPdfWithQr:
				//onQrContentTypeClose();
				break;
			default:
				break;
		}
		currentStep = step;
	};

	//skip ProfileSelect as there is a special button for it
	const onNext = () => onChange(step + 1 + (step === pagePdfViewer ? 1 : 0));
	const onPrevious = () => onChange(step - 1, true);
	const onProfileSelect = () => onChange(pageProfileSelect);

	return (

		<div>
			<Steps current={step}>
				<Steps.Item title="Документ" description="Загрузите PDF" />
				<Steps.Item title="Профиль" description="Выбрать" />
				<Steps.Item title="QR код" description="Расположение" />
				<Steps.Item title="QR код" description="Содержание" />
				<Steps.Item title="Результат" description="Скачать" />
				<Steps.Item title="Профиль" description="Сохранить" />
			</Steps>
			<hr />
			<Panel>
				<Switch test={step}>
					<div value={pagePdfViewer}>
						<PdfViewer 
							pdfFile={pdfFile} 
							setPdfFile={setPdfFile} 
							pageNumber={pageNumber}
							setPageNumber={setPageNumber}
							setPageSize={setPageSize}
							pdfScale={pdfScale}
						/>
					</div>
					<div value={pageProfileSelect}>
						<ProfileSelect
							userLogin={userLogin}
							setUserLogin={setUserLogin}
							userProfiles={userProfiles}
							setUserProfiles={setUserProfiles}
							setQrSettingsByProfile={setQrSettingsByProfile}
							selectedProfile={selectedProfile}
							setSelectedProfile={setSelectedProfile}
							showAlert={handleOpenModalAlert}
						/>
					</div>					
					<div value={pageQrCodeSettings}>
						<QrCodeSettings
							pdfFile={pdfFile} 
							pageNumber={pageNumber}
							pageSize={pageSize}
							pdfScale={pdfScale}
							sizeQr={sizeQr}
							setSizeQr={setSizeQr}
							qrPosition={qrPosition} 
							setQrPosition={setQrPosition}
						/>
					</div>
					<div value={pageQrCodeContent}>
						<QrCodeContent
							contentType={contentType}
							setContentType={setContentType}
							docType={docType}
							setDocType={setDocType}
							textQr={textQr}
							setTextQr={setTextQr}
							urlQr={urlQr}
							setUrlQr={setUrlQr}
							textPage={textPage}
							setTextPage={setTextPage}
							isQrIncludedInAllPages={isQrIncludedInAllPages}
							setIsQrIncludedInAllPages={setIsQrIncludedInAllPages}
						/>
					</div>
					<div value={pageDownloadPdfWithQr}>
						{canDownload ? <DownloadPdfWithQr/> : <ErrorProcessingPdf/>}
					</div>
					<div value={pageProfileSave}>
						<ProfileSave
							showAlert={handleOpenModalAlert}
						/>
					</div>
				</Switch>
			</Panel>
			<hr />
			<div className='page-nav-buttons-panel'>
				<ButtonGroup>
					<Button size="lg" onClick={onPrevious} disabled={step === 0}>
						Назад
					</Button>
					{step === pagePdfViewer && 
					<Button size="lg" onClick={onProfileSelect}>Профиль</Button>
					}
					<Button size="lg" onClick={onNext} disabled={step === maxSteps}>
						Далее
					</Button>
				</ButtonGroup>
			</div>

			<Modal open={openModalAlert} onClose={handleCloseModalAlert}>
				<Modal.Body>
					{ modalAlertMessage }
				</Modal.Body>
				<Modal.Footer>
					<Button onClick={handleCloseModalAlert} size="lg">
						Ok
					</Button>
				</Modal.Footer>
			</Modal>			
		</div>
	);
};

export default AppSteps;