import { useCallback, useState } from 'react';
import { useDrop } from 'react-dnd';
import { ItemTypes } from '../dnd/ItemTypes';
import { DragBox } from '../dragBox/DragBox';
import update from 'immutability-helper';
import { Document, Page } from 'react-pdf';
import { createDocument } from '../utils/RestHelper';

export const PagePreview = ({ qrPosition, setQrPosition, pdfPageSize, qrSize, pdfFile, pageNumber, pdfScale, children, }) => {
    const sylePadding = 1;
    const styles = {
        position: 'relative',
        border: '1px dotted gray',
        padding: sylePadding + 'px',
        height: (pdfPageSize.height + 2*sylePadding)+'px',
        width: (pdfPageSize.width + 2*sylePadding)+'px',
    };

    const [box, setBox] = useState({ 
        top: qrPosition.top, 
        left: qrPosition.left, 
    });

    const moveBox = useCallback((left, top) => {
        setBox(update(box, {
                $merge: { left, top },
        }));
    }, [box, setBox]);

    const adjustPosition = (val, pval) => {
        val = val < 0 ? 0 : val;
        val = val + qrSize > pval ? pval - qrSize : val;
        return val;
    }

    const [, drop] = useDrop(() => ({
        accept: ItemTypes.DragBox,
        drop(item, monitor) {
            const delta = monitor.getDifferenceFromInitialOffset();
            let left = Math.round(item.left + delta.x);
            let top = Math.round(item.top + delta.y);
            //adjust position to not exit the boundaries
            left = adjustPosition(left, pdfPageSize.width);
            top = adjustPosition(top, pdfPageSize.height);
            //drop element
            moveBox(left, top);
            //store coordinates
            setQrPosition({left, top});
            return undefined;
        },
    }), [moveBox]);

    const onDocumentLoadSuccess = ({ numPages }) => {
        if(pdfFile) {
            createDocument(pdfFile)
            .then(docId => {
                if (docId) {
                    localStorage.setItem('docId', docId);
                }
            });
        }
    }

    return (
        <div ref={drop} style={styles}>
            <Document
                file={pdfFile}
                onLoadSuccess={onDocumentLoadSuccess}
            >
                <Page 
                    pageNumber={pageNumber} 
                    scale={pdfScale} //96/72
                />
            </Document>

            <DragBox left={box.left} top={box.top}>
            {children}
            </DragBox>
        </div>
    );
};
