import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { ButtonGroup, Button, SelectPicker, Form } from 'rsuite';
import { getProfiles } from '../utils/RestHelper';

const ProfileSelect = ({
    userLogin,
    setUserLogin,
    userProfiles,
    setUserProfiles,
    setQrSettingsByProfile,
    selectedProfile, 
    setSelectedProfile,
    showAlert}) => {   

    const [newUserId, setNewUserId] = useState('');
    const [isChangingLogin, setIsChangingLogin] = useState(false);
    
    let profileOptions = [];

    const getProfileOptions = () => {
        if (!userProfiles && !Array.isArray(userProfiles) ) {
            return;
        }
        profileOptions = [];
        userProfiles.forEach(i => {
            profileOptions.push({ 
                label: i.name, 
                value: i.id
            });
        });
    }

    getProfileOptions();

    const onSelectProfile = id => {
        setSelectedProfile(id);

        let data;
        if (id) {
            data = userProfiles.find(i => i.id === id);
        } else {
            //default when no profile selected
            data = {
                size: 128,
                posX: 0,
                posY: 0,
                pageNum: 1,
                id: null
            };
        }
        setQrSettingsByProfile(data);
    }

    const onChangeLogin = () => {
        setIsChangingLogin(true);
    }

    const onCancelChangeLogin = () => {
        setIsChangingLogin(false);
    }

    const onLogin = () => {
        if (!newUserId) {
            //show msg
            showAlert('Укажите номер телефона или e-mail');
            return;
        }
        setUserProfiles([]);
        getProfiles(newUserId)
        .then(data => {
            if (!data) {
                return;
            }
			setUserLogin(newUserId);
            localStorage.setItem('userLogin', newUserId);
            setIsChangingLogin(false);
            setUserProfiles(data);
            //next is not working don't know why, in getProfileOptions() userProfiles are not updated
            //getProfileOptions();
            //setSelectedOption();
        },
        reason => {
            console.log(reason);
        });

    }

    const setSelectedOption = () => {
        //if there is only one profile set it
        if (profileOptions.length === 1) {
            onSelectProfile(profileOptions[0].value);
        }
    }

	useEffect(() => {
        setSelectedOption();
	}, []);	

    return (
        <>
            <h3>Выбор профиля</h3>
            <p>Если раньше вы не сохраняли профиль пропустите этот шаг и переходите по кнопке Далее.</p>
            <p>Выбирая ранее сохранённый профиль, вы применяете его настройки к текущему документу. К настройкам относятся размер и расположение QR кода на заданной странице документа.</p>
            <p>Профиль можно сохранить на последнем шаге, в него записываются настройки из текущего документа.</p>
            <p>К одному логину может быть привязано несколько профилей, каждый профиль соответствует определённому типу документа.</p>
            <hr/>
            { userLogin && !isChangingLogin &&
            <>
                <p>Логин: {userLogin}</p>
                <Form layout="inline">
                    <Form.Group>
                    <Form.ControlLabel>Тип документа</Form.ControlLabel>
                    <SelectPicker
                            value={selectedProfile}
                            placeholder="Тип документа"
                            data={profileOptions}
                            cleanable={true}
                            onChange={onSelectProfile}
                        />
                    </Form.Group>
                </Form>
                <Button onClick={onChangeLogin}>Использовать другой логин</Button>
            </>
            }
            { (!userLogin || isChangingLogin) &&
            <>
                <Form layout="inline">
                    <Form.Group controlId="userLogin">
                        <Form.Control 
                            name="userLogin" 
                            placeholder="Номер телефона или e-mail"
                            value={newUserId}
                            onChange={setNewUserId} />
                    </Form.Group>
                </Form>
                <ButtonGroup>
                    <Button onClick={onLogin}>Загрузить</Button>
                    <Button onClick={onCancelChangeLogin}>Отмена</Button>
                </ButtonGroup>
            </>
            }
        </>
    );

}

export default ProfileSelect;