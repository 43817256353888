import React, { useState } from 'react';
import { Document, Page } from 'react-pdf';
import { ButtonToolbar, IconButton } from 'rsuite';
import { ArrowLeft, ArrowRight } from '@rsuite/icons';
import FileUploader from './../fileUploader/FileUploader';

const PdfViewer = props => {
    const instructionStyles = {
        padding: '10px 0 20px 0',
    };
    const buttonToolbarStyles = {
        'marginTop': '20px',
        'textAlign': 'center'
    };    

    const setPdfFile = props.setPdfFile;
    const setPageSize = props.setPageSize;
    const setPageNumber = props.setPageNumber;
    let pdfFile = props.pdfFile;
    let pageNumber = props.pageNumber;
    
    const [numPages, setNumPages] = useState(null);
  
    function onPageLoadSuccess(page) {
        setPageSize({width: page.width, height: page.height});
    }

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
        if (pageNumber){
            setPageNumber(pageNumber);
        } else {
            setPageNumber(1);
        }
    }

    function changePage(offset) {
        setPageNumber(prevPageNumber => prevPageNumber + offset);
    }

    function previousPage() {
        changePage(-1);
    }

    function nextPage() {
        changePage(1);
    }
       
    const onHandleFile = (file) => {
        if (!file) {
            return;
        }

        setPdfFile(file);
        setPageNumber(1);
        setNumPages(null);
    }    

    return (
        <>
            <div style={instructionStyles}>
                <h3>Документ</h3>
                <p>Загрузите PDF документ.</p>
                <p>Выберите страницу на которой хотите разместить QR код. Далее, на шаге "Содержание QR кода", можно будет выбрать опцию размещения QR кода на всех страницах документа.</p>
                <p>По кнопке "Далее" перейдите к выбору параметров QR.</p>
                <p>Если у вас есть сохранённый профиль, то его можно выбрать по кнопке "Профиль". В этом случае настройки из профиля будут применены к ткущему документу. Профиль можно сохранить на последнем шаге.</p>
                <p>Если профиля нет, то переходите сразу по кнопке "Далее".</p>
				<p>Сервис предоставляется 'Как есть' и не несёт ответственности за возможные ошибки в обработке загружаемых документов.</p>
            </div>

            <FileUploader handleFile={onHandleFile}/>
            {
                numPages != null && numPages > 1 ?
                <div style={buttonToolbarStyles}>
                    <ButtonToolbar>
                        <IconButton 
                            icon={<ArrowLeft/>} 
                            placement="left"
                            disabled={pageNumber <= 1}
                            onClick={previousPage}/>

                        <span style={{padding: "0 10px"}}>
                            Страница {pageNumber || (numPages ? 1 : '--')} из {numPages || '--'}
                        </span>

                        <IconButton 
                            icon={<ArrowRight/>} 
                            placement="right"
                            disabled={pageNumber >= numPages}
                            onClick={nextPage}/>
                    </ButtonToolbar>            
                </div>
                : null
            }
            <Document
                file={pdfFile}
                onLoadSuccess={onDocumentLoadSuccess}
            >
                <Page 
                    pageNumber={pageNumber} 
                    onLoadSuccess={onPageLoadSuccess}
                    scale={props.pdfScale} //96/72
                />
            </Document>
        </>
    );

}

export default PdfViewer;