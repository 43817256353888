import React from 'react';
import './App.css';
import { Container, Header, Navbar, Nav, Content, Footer } from 'rsuite';
import { Qrcode } from '@rsuite/icons';
import AppSteps from './AppSteps';

// import default style
import 'rsuite/dist/rsuite.min.css';

const App = () => {
	const year = new Date().getFullYear();

	return (
		<div className="show-container">
			<Container className="main-layout">
				<Header className="main-header">
					<div className='header-title'>Сервис для добавления QR кода в документ PDF</div>
					<Navbar appearance="inverse" className="main-navbar">
						<Navbar.Brand>
							<span>QR MARK</span>
						</Navbar.Brand>
						<Nav pullRight>
							<Nav.Item icon={<Qrcode />}>Settings</Nav.Item>
						</Nav>
					</Navbar>
				</Header>
				<Content className="pd main-content"><AppSteps/></Content>
				<Footer className="footer pd">Копирайт 2022 - {year} АБУ</Footer>
			</Container>
		</div> 
	);
};

export default App;