import { useDrag } from 'react-dnd';
import { ItemTypes } from '../dnd/ItemTypes';
const style = {
    position: 'absolute',
    backgroundColor: 'white',
    cursor: 'move',
    padding1: '0.5rem 1rem',
    border1: '1px dashed gray',
};
export const DragBox = ({ id, left, top, children, }) => {
    const [{ isDragging }, drag] = useDrag(() => ({
        type: ItemTypes.DragBox,
        item: { id, left, top },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    }), [id, left, top]);
    if (isDragging) {
        return <div ref={drag}/>;
    }
    return (<div ref={drag} style={{ ...style, left, top }} role="DragBox">
			{children}
		    </div>);
};
