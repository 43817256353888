import React from 'react';
import { Button } from 'rsuite';

const ErrorProcessingPdf = props => {
    return (
        <>
            <p>Не удалось преобразовать документ.</p>
            <Button appearance="primary" size="lg" href="/">Вернуться в начало</Button>
        </>
    );

}

export default ErrorProcessingPdf;