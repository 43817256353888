import config from '../config.json';

/**
 * Creates document
 * 
 * @returns id of created document or false
 */
export const createDocument = async (pdfFile) => {
    try {
        const formData  = new FormData();

        formData.append('description', 'test');
        formData.append('file', pdfFile);
        
        const response = await fetch(`${config.apiUrl}/documents`, {
            method: 'POST',
            body: formData
        });

        const data = await response.json();
        
        return data.id;
    }
    catch (e) {
        console.log(e);
        return false;
    }
}

/**
 * Sets QR data for the document
 * 
 * @returns id of updated document part for qrCode or false
 */
 export const setQrData = async (docId, qrData) => {
    try {
        const response = await fetch(`${config.apiUrl}/documents/${docId}/qrcode`, {
            method: 'POST',
            body: JSON.stringify(qrData),
            headers: {
              'Content-Type': 'application/json'
            }
        });

        const data = await response.json();

        if (!response.ok) {
            return false;
        }
        
        return data.qrCode.id;
    }
    catch (e) {
        console.log(e);
        return false;
    }
}

/**
 * Creates user Profile (and User itself if does not exist)
 * 
 * @returns id of created profile or false
 */
export const createProfile = async (docId, data) => {
    try {
        const response = await fetch(`${config.apiUrl}/documents/${docId}/profile`, {
//        const response = await fetch(`${config.apiUrl}/documents/${docId}/profile?XDEBUG_SESSION_START=netbeans-xdebug`, {
        method: 'POST',
            body: JSON.stringify(data),
            headers: {
              'Content-Type': 'application/json'
            }
        });

        const ret = await response.json();

        if (!ret.profile) {
            return false;
        }
        
        return ret.profile.id;
    }
    catch (e) {
        console.log(e);
        return false;
    }
}

/**
 * Retrieves all profiles for the user
 * 
 * @returns collection of profiles or false
 */
export const getProfiles = async (userId) => {
    try {
        const response = await fetch(`${config.apiUrl}/users/${userId}/profiles`, {
        method: 'GET',
            headers: {
              'Content-Type': 'application/json'
            }
        });

        const ret = await response.json();

        if (!Array.isArray(ret)) {
            return false;
        }
        
        return ret;
    }
    catch (e) {
        console.log(e);
        return false;
    }
}
