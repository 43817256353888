import React from 'react';
import QRCode from "react-qr-code";
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { PagePreview } from '../pagePreview/PagePreview';
import QrCodeSizeSelector from '../qrCodeSizeSelector/QrCodeSizeSelector';

const QrCodeSettings = ({
    pdfFile, 
    pageNumber, 
    pageSize, 
    pdfScale,
    sizeQr, 
    setSizeQr,
    qrPosition, 
    setQrPosition
}) => {

    return (
        <DndProvider backend={HTML5Backend}>
            <h3>Размер и расположение QR</h3>
            <QrCodeSizeSelector 
                setSizeQr={setSizeQr}
                sizeQr={sizeQr} />
            <p>Выберите оптимальное расположение QR кода перетаскивая его мышью</p>
            <p>ВАЖНО: Итоговое расположение QR кода в конечном документе может отличаться</p>
            <p></p>
            <div style={{display: 'none'}}>
                <div>текущие координаты:</div>
                <span>X: {qrPosition.left}, </span>
                <span>Y: {qrPosition.top}</span>
            </div>
            <PagePreview 
                qrPosition={qrPosition}
                setQrPosition={setQrPosition} 
                pdfPageSize={pageSize} 
                qrSize={sizeQr}
                pdfFile={pdfFile}
                pageNumber={pageNumber}
                pdfScale={pdfScale}
            >
                <QRCode value="demo" size={sizeQr} />
            </PagePreview>
        </DndProvider>		
    );
};

export default QrCodeSettings;