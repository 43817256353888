import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { Button, Form } from 'rsuite';
import { createProfile } from '../utils/RestHelper';

const ProfileSave = ({showAlert}) => {
	const [userLogin, setUserLogin] = useState('');
	const [profileName, setProfileName] = useState('');
	const [isSaving, setIsSaving] = useState(false);
    
	const [canSave, setCanSave] = useState(true);
	const [saveOk, setSaveOk] = useState(false);

	useEffect(() => {
        let userLoginStored = localStorage.getItem('userLogin');		
        userLoginStored && setUserLogin(userLoginStored);
	}, []);	

    const onCreateProfile = () => {
        let docId = localStorage.getItem('docId');		
        if(!docId) {
            showAlert('Ошибка: документ не найден');
            return;
        }
        if(!userLogin) {
            showAlert('Укажите номер телефона или e-mail');
            return;
        }

        localStorage.setItem('userLogin', userLogin);
        localStorage.setItem('profileName', profileName);

        setIsSaving(true);

        let data = {
            profile: {
              user: {
                login: userLogin
              },
              name: profileName
            }
        }        
    
        createProfile(docId, data)
        .then(data => {
            //Профиль успешно сохранен! спасибо что воспользовались сервисом
            //на главную
            setSaveOk(true);
        },
        reason => {
            //компонент сообщения об ошибке
            setSaveOk(false);
            setIsSaving(false);
            console.log(reason);
        }).finally(()=>{
            setCanSave(false);
        });
    }

    return (
        <>
        {   canSave ?
        <div>
            <h3>Сохранение профиля</h3>
            <p>Введите свой e-mail или номер телефона. К нему будут привязаны выбранные настройки для размера и расположения QR.</p>
            <p>В следующий раз, после загрузки документа, можно будет выбрать свой профиль. Тогда эти настройки применятся автоматически.</p>
            <p>Если предполагается несколько типов документов, с разными настройками, укажите также и поле "Тип документа".</p>
            <p>Если этот тип документа сохранялся раньше он будет обновлён.</p>
            <Form>
                <Form.Group controlId="userLogin">
                    <Form.Control 
                        name="userLogin" 
                        placeholder="Номер телефона или e-mail"
                        value={userLogin}
                        onChange={value=>{setUserLogin(value)}} />
                </Form.Group>
                <Form.Group controlId="profileName">
                    <Form.Control 
                        name="profileName" 
                        placeholder="Тип документа"
                        value={profileName}
                        onChange={value=>{setProfileName(value)}} />
                </Form.Group>
                <Button appearance="primary" size="lg" onClick={onCreateProfile} disabled={isSaving}>Сохранить</Button>
            </Form>

        </div>
        : ( saveOk ?
        <div>
            <p>Профиль успешно сохранён</p>
            <Button appearance="primary" size="lg" href="/">Вернуться в начало</Button>
        </div>
        :
        <div>
            <p>Не удалось сохранить профиль</p>
            <Button appearance="primary" size="lg" href="/">Вернуться в начало</Button>
        </div>
        )}
        </>
    );

}

export default ProfileSave;