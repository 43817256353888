import { setQrData } from '../utils/RestHelper';

/**
 * Updates qrCode on backend
 * @returns boolean
 */
export const updateQrCodeData = async (context) => {
    let {contentType,textQr,urlQr,docType,textPage,sizeQr,qrPosition,pageNumber,isQrIncludedInAllPages} = context;
    //get docId from localstorage, also move it into state
    let docId = localStorage.getItem('docId');		
    if(!docId) {
        return;
    }

    let scope = 0
    let text = '';

    switch(contentType) {
        case 'CCT01':
            scope = 1;
            text = textQr;
            break;
        case 'CCT02':
            scope = 2;
            text = urlQr;
            break;
        case 'CCT03':
            switch(docType) {
                case 'CDT01':
                    scope = 4;
                    break;
                case 'CDT02':
                    scope = 5;
                    break;
                default:
                    break;
            }
            break;
        case 'CCT04':
            scope = 3;
            text = textPage;
            break;
        default:
            break;
        }

    let qrCodeData = {
        qrCode: {
            text,
            size: sizeQr,
            posX: qrPosition.left,
            posY: qrPosition.top,
            pageNum: isQrIncludedInAllPages ? -1 : pageNumber,
            scope
        }        
    }

    let canDownload = false;

    const ret = await setQrData(docId, qrCodeData)
    .then(data => {
        //set state that doc is generated on the server and can be doownloaded
        //data can be false in case of error or qrCodeId
        canDownload = !!data;
    },
    reason => {
        console.log(reason);
    });

    return canDownload;
};
