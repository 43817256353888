import React from 'react';
import { Radio, RadioGroup, Input, Checkbox } from 'rsuite';
import Switch from '../utils/Switch';

const QrCodeContent = ({
    contentType,
    setContentType,
    docType,
    setDocType,
    textQr,
    setTextQr,
    urlQr,
    setUrlQr,
    textPage,
    setTextPage,
    isQrIncludedInAllPages,
    setIsQrIncludedInAllPages}) => {

    const handleChange = (value) => {
        setContentType(value);
    }

    const handleDocTypeChange = (value) => {
        setDocType(value);
    }

    return (
        <>
            <h3>Содержание QR кода</h3>
            <RadioGroup
                inline
                name="qrContentRadioList"
                value={contentType}
                onChange={handleChange}
            >
                <Radio value="CCT01">Текст</Radio>
                <Radio value="CCT02">Внешняя ссылка</Radio>
                <Radio value="CCT03">Ссылка на PDF документ на этом сервере</Radio>
                <Radio value="CCT04">Ссылка на страницу с текстом на этом сервере</Radio>
            </RadioGroup>
            <Switch test={contentType}>
                <div value={'CCT01'}>
                    <p>При сканировании QR будет отображаться заданный ниже текст, длина текста зависит от размера QR</p>
                    <p></p>
                    <Input 
                        as="textarea" 
                        id="textQr"
                        value={textQr} onChange={value=>{ setTextQr(value)}}
                        rows={3} 
                        placeholder="Введите текст который будет зашифрован в QR коде, длина текста зависит от размера QR" />
                </div>
                <div value={'CCT02'}>
                    <p>При сканировании QR будет отображаться заданная ниже ссылка</p>
                    <p></p>
                    <Input 
                        id="urlQr"
                        value={urlQr} onChange={value=>{ setUrlQr(value)}}
                        placeholder="Введите ссылку" />
                </div>
                <div value={'CCT03'}>
                   <p>С этой опцией будет сгенерирована ссылка для скачивания ранее загруженного PDF документа, QR код будет содержать эту ссылку</p>
                   <p>Выберите какой документ должен быть доступен для скачивания</p>
                   <RadioGroup
                        inline
                        name="docTypeRadioList"
                        value={docType}
                        onChange={handleDocTypeChange}
                    >
                        <Radio value="CDT01">оригинальный (без QR)</Radio>
                        <Radio value="CDT02">измененный (с QR)</Radio>
                    </RadioGroup>
                </div>
                <div value={'CCT04'}>
                    <p>С этой опцией будет сгенерирована страница с заданным ниже текстом, QR код будет содержать ссылку на эту страницу</p>
                    <p></p>
                    <Input 
                        as="textarea" 
                        id="textPage"
                        value={textPage} onChange={value=>{ setTextPage(value)}}
                        rows={3} 
                        placeholder="Введите текст который будет показан на странице" />
                </div>
            </Switch>

            <Checkbox
                checked={isQrIncludedInAllPages}
                onChange={(value, checked)=>{setIsQrIncludedInAllPages(checked)}}
            > Добавить QR код на все страницы документа</Checkbox>
            <p>&nbsp;</p>
            <p>- Текст, от пары слов до пары предложений, если текста много, выберите опцию: "Ссылка на страницу с текстом"</p>
            <p>- Внешняя ссылка, на страницу в интернете, или на документ для скачивания, на вашем сайте или в облаке YandexDisk</p>
            <p>- Ссылка на ваш документ PDF, на этом сервере (в этом случае он не будет удалён в течение ближайшего часа)</p>
            <p>- Ссылка на текстовую страницу, которая будет содержать ваш текст, доступная на этом сервере</p>
        </>
    );

}

export default QrCodeContent;