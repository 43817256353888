import React, { useState } from 'react';
import { Radio, RadioGroup } from 'rsuite';
import { useEffect } from 'react';

const QrCodeSizeSelector = ({setSizeQr,sizeQr}) => {
    const [size, setSize] = useState("M");

    const sizes = {
        'S': 64,
        'M': 128,
        'L': 256
    };

	useEffect(() => {
        const getSizeByPx = pxSize => {
            for (const idx in sizes) {
                if (sizes[idx] === pxSize) {
                    return idx;
                }
            }
        }

        if (sizeQr) {
            const s = getSizeByPx(sizeQr);
            if (s) {
                setSize(s);
            }
        }
    }, []);	

    const handleChange = (value) => {
        setSize(value);
        setSizeQr(sizes[value]);
    }

    return (
        <>
            <p>Выберите размер QR кода</p>
            <RadioGroup
                inline
                name="radioList"
                value={size}
                onChange={handleChange}
            >
                <Radio value="S">Маленький</Radio>
                <Radio value="M">Средний</Radio>
                <Radio value="L">Большой</Radio>
            </RadioGroup>
        </>
    );

}

export default QrCodeSizeSelector;